import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'TemplateTopBanner',
  mixins: [imageUrlMixin],
  props: ['topBanner'],
  data() {
    return {
      slide: 0,
      slides: [
        `${this.imageDomain}/assets/img/middle_banner_01.jpg`,
        `${this.imageDomain}/assets/img/middle_banner_02.jpg`,
        `${this.imageDomain}/assets/img/middle_banner_03.jpg`,
        `${this.imageDomain}/assets/img/middle_banner_04.jpg`,
      ],
      myOptions: {
        autoplay: this.topBanner.length > 1,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        autoplaySpeed: 4000,
        rtl: true,
        dots: false,
        speed: 2000,
        navButtons: this.topBanner.length > 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: false,
            },
          },
        ],
      },
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
    };
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.$refs.topMain) {
    //     this.$refs.topMain.reload();
    //   }
    // }, 2000);
  },
};
